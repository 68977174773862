import type {NetworkStatus} from '@bishop/shared-types'

/**
 * Determines network status based on the provided input.
 *
 * @param data - Data received through the network
 * @param error - Error received through the network
 * @param isLoading - Flag indiciating whether or not data is still loading
 * @returns Network status
 */
export default function getNetworkStatus(
  data: unknown,
  error: unknown,
  isLoading: boolean,
): NetworkStatus {
  if (isLoading) {
    return 'pending'
  }

  if (error) {
    return 'rejected'
  }

  if (data) {
    return 'resolved'
  }

  return 'idle'
}
